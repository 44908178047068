import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, List, Typography, Badge, Space, Collapse } from "antd";
import { ICall, ICallLog } from "../../utils/interfaces";
import VideosessionService from "../../utils/VideosessionService";

const { Title, Text } = Typography;
const { Panel } = Collapse;

const VideosessionLogs = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [call, setCall] = useState<ICall | null>(null);
  const [logs, setLogs] = useState<ICallLog[]>([]);

  useEffect(() => {
    if (!id) return;

    const fetchCallLogs = async () => {
      try {
        const fetchedCall = await VideosessionService.getCall(id);
        setCall(fetchedCall);

        const sortedLogs = [...fetchedCall.logs].sort((a, b) =>
          new Date(b.time).getTime() - new Date(a.time).getTime()
        );
        setLogs(sortedLogs);
      } catch (error) {
        console.error("Failed to fetch call logs", error);
      }
    };

    fetchCallLogs();
  }, [id]);

  return (
    <div style={{ padding: 20, textAlign: "left" }}>
      <Button onClick={() => navigate(`/admin/videosessions/${id}`)}>
        Back to Video Session
      </Button>
      <Title level={2}>Call Logs</Title>
      <List
        bordered
        dataSource={logs}
        renderItem={(log) => (
          <List.Item>
            <Space>
              {log.interpreterId ? (
                <Badge color="blue" text="Interpreter" />
              ) : log.companyId ? (
                <Badge color="green" text="Company" />
              ) : (
                <Badge color="gray" text="Unknown" />
              )}
              <Text>{new Date(log.time).toLocaleString()}</Text>
              <Text strong>{log.event}</Text>
              {log.state && (
                <Text>
                  [Video: {log.state.videoEnabled ? "On" : "Off"}, Audio: {log.state.audioEnabled ? "On" : "Off"}]
                </Text>
              )}
              {log.webrtcData && (
                <Collapse>
                  <Panel header="WebRTC Data" key="1">
                    <div>
                      <Text strong>Inbound Audio:</Text>
                      <ul>
                        <li>Packets Lost: {log.webrtcData.inboundAudio.packetsLost}</li>
                        <li>Jitter: {log.webrtcData.inboundAudio.jitter}</li>
                        <li>Round Trip Time: {log.webrtcData.inboundAudio.roundTripTime} ms</li>
                        <li>Bitrate: {log.webrtcData.inboundAudio.bitrate} kbps</li>
                      </ul>
                      <Text strong>Inbound Video:</Text>
                      <ul>
                        <li>Packets Lost: {log.webrtcData.inboundVideo.packetsLost}</li>
                        <li>Jitter: {log.webrtcData.inboundVideo.jitter}</li>
                        <li>Round Trip Time: {log.webrtcData.inboundVideo.roundTripTime} ms</li>
                        <li>Bitrate: {log.webrtcData.inboundVideo.bitrate} kbps</li>
                      </ul>
                      <Text strong>Outbound Audio:</Text>
                      <ul>
                        <li>Packets Sent: {log.webrtcData.outboundAudio.packetsSent}</li>
                        <li>Bitrate: {log.webrtcData.outboundAudio.bitrate} kbps</li>
                      </ul>
                      <Text strong>Outbound Video:</Text>
                      <ul>
                        <li>Packets Sent: {log.webrtcData.outboundVideo.packetsSent}</li>
                        <li>Bitrate: {log.webrtcData.outboundVideo.bitrate} kbps</li>
                      </ul>
                    </div>
                  </Panel>
                </Collapse>
              )}
            </Space>
          </List.Item>
        )}
      />
    </div>
  );
};

export default VideosessionLogs;