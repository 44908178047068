import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "./components/AdminLayout";
import AuthLayout from "./components/AuthLayout";
import { AuthProvider } from "./hooks/authHook";
import Companies from "./pages/Companies";
import Company from "./pages/Company";
import Dashboard from "./pages/Dashboard";
import EditInterpreter from "./pages/EditInterpreter";
import Feedbacks from "./pages/Feedbacks";
import FindInterpreter from "./pages/FindInterpreter";
import Interpreter from "./pages/Interpreter";
import Interperters from "./pages/Interpreters";
import NewSession from "./pages/NewVideosession";
import SignIn from "./pages/SignIn";
import Videosession from "./pages/Videosession";
import Videosessions from "./pages/Videosessions";
import Vouchers from "./pages/Vouchers";
import VideosessionLogs from "./pages/VideosessionLogs";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="sign-in"
            element={
              <AuthLayout>
                <SignIn />
              </AuthLayout>
            }
          />
          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="interpreters">
              <Route index element={<Interperters />} />
              <Route path=":id" element={<Interpreter />} />
              <Route path=":id/edit" element={<EditInterpreter />} />
            </Route>
            <Route path="companies">
              <Route index element={<Companies />} />
              <Route path=":id" element={<Company />} />
            </Route>
            <Route path="videosessions">
              <Route index element={<Videosessions />} />
              <Route path="new" element={<NewSession />} />
              <Route path=":id" element={<Videosession />} />
              <Route path=":id/logs" element={<VideosessionLogs />} />
            </Route>
            <Route path="vouchers">
              <Route index element={<Vouchers />} />
            </Route>
            <Route path="feedbacks">
              <Route index element={<Feedbacks />} />
            </Route>
            <Route path="find-interpreter">
              <Route index element={<FindInterpreter />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
