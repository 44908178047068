import ApiService from "./ApiService";
import {
  IAdditionalOption,
  ICall,
  ICreateVideosessionData,
  IFilterItems,
  IVideosession,
  SessionLogs,
} from "./interfaces";

class VideosessionsService extends ApiService {
  public async getVideosession(id: string | number): Promise<IVideosession> {
    const response = await this.request({
      url: `videosessions/${id}/admin`,
    });

    return response.data;
  }

  public async getCall(id: string | number): Promise<ICall> {
    const response = await this.request({
      url: `videosessions/${id}/call/admin`,
    });

    return response.data;
  }

  public async getSessionLogs(id: string): Promise<SessionLogs> {
    const response = await this.request({
      url: `videosessions/${id}/logs`,
    });

    return response.data;
  }

  public async getVideosessions({
    current,
    pageSize,
    status,
    email,
    companyName,
    interpreterEmail,
    from,
    to,
    withCustomId
  }: IFilterItems): Promise<{
    videosessions: IVideosession[];
    count: number;
    email?: string;
    companyName?: string;
    interpreterEmail?: string;
  }> {
    const offset = ((current as number) - 1) * (pageSize as number);
    let url = `/videosessions?offset=${offset}`;
    if (pageSize) url += `&limit=${pageSize}`;
    if (status) url += `&status=${status}`;
    if (email) url += `&email=${email}`;
    if (companyName) url += `&companyName=${companyName}`;
    if (interpreterEmail) url += `&interpreterEmail=${interpreterEmail}`;
    if (from) url += `&from=${from}`;
    if (to) url += `&to=${to}`;
    if (withCustomId) url += `&withCustomId=true`;

    const response = await this.request<{
      videosessions: IVideosession[];
      count: number;
    }>({
      url,
    });

    return response.data;
  }

  public async changeStartTime(
    startTime: string,
    id: number
  ): Promise<IVideosession> {
    const response = await this.request({
      url: `videosessions/${id}/start-time`,
      method: "PATCH",
      data: {
        startTime,
      },
    });

    return response.data;
  }

  public async updateVideosessionField(
    name: string,
    value: string | IAdditionalOption[] | undefined,
    id: number
  ): Promise<IVideosession> {
    const response = await this.request({
      url: `videosessions/${id}/admin`,
      method: "PATCH",
      data: {
        [name]: value,
      },
    });

    return response.data;
  }

  public async createVideosession(
    data: ICreateVideosessionData
  ): Promise<IVideosession> {
    const response = await this.request({
      url: `videosessions/admin`,
      method: "POST",
      data,
    });

    return response.data;
  }

  public async downloadLogsRange(data: {
    from: string;
    to: string;
    fromDate?: string;
    toDate?: string;
  }) {
    const { from, to, fromDate, toDate } = data;
    const response = await this.request({
      url: `videosessions/logs?from=${from}&to=${to}`,
      method: "GET",
    });
    const file = new File(
      [response.data],
      `session_logs_${`${fromDate}_${toDate}`}.csv`
    );
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `session_logs_${`${fromDate}_${toDate}`}.csv`
    );
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }

  public async inviteInterpreter(
    sessionId: number,
    interpeterId: number
  ): Promise<void> {
    const response = await this.request({
      url: `videosessions/${sessionId}/invite/${interpeterId}`,
      method: "PUT",
    });

    return response.data;
  }

  public async changeStatusToCancel(
    sessionId: number,
  ): Promise<IVideosession> {
    const response = await this.request({
      url: `videosessions/${sessionId}/status/CANCELLED`,
      method: "PUT",
    });

    return response.data;
  }

  public async payEarningDifference(
    sessionId: number,
  ): Promise<IVideosession> {
    const response = await this.request({
      url: `videosessions/${sessionId}/interpreter-payment`,
      method: "PUT",
    });

    return response.data;
  }

  public async changeDuration(
    id: number,
    duration: number,
  ): Promise<IVideosession> {
    const response = await this.request({
      url: `videosessions/${id}/duration/${duration}`,
      method: "PUT",
    });

    return response.data;
  }
}

const instance = new VideosessionsService();

export default instance;
