import ApiService from "./ApiService";
import { ICompany, IFilterItems } from "./interfaces";

class CompanyService extends ApiService {
  public async getCompanies({
    current,
    pageSize,
    status,
    email,
    companyName,
    balance,
  }: IFilterItems): Promise<{
    companies: ICompany[];
    count: number;
  }> {
    const offset = ((current as number) - 1) * (pageSize as number);
    let url = `/company/admin?offset=${offset}`;
    if (pageSize) url += `&limit=${pageSize}`;
    if (status) url += `&status=${status}`;
    if (email) url += `&email=${email}`;
    if (companyName) url += `&companyName=${companyName}`;
    if (balance) url += `&balance=${balance}`;

    const response = await this.request<{
      companies: ICompany[];
      count: number;
    }>({
      url,
    });

    return response.data;
  }

  public async getCompany(id: string): Promise<ICompany> {
    const response = await this.request({
      url: `company/${id}`,
    });

    return response.data;
  }

  public async deleteCompany(
    id: string | number,
    code: string,
    secret: string
  ): Promise<ICompany> {
    const response = await this.request({
      url: `company/${id}?code=${code}&secret=${secret}`,
      method: "delete",
    });

    return response.data;
  }

  public async getEmployeeRegisterCode(id: number): Promise<{ code: string }> {
    const response = await this.request({
      url: `company/employee/${id}/new-link`,
    });

    return response.data;
  }

  public async sendInviteEmpoyeeEmail(id: number): Promise<{ code: string }> {
    const response = await this.request({
      url: `company/employee/${id}/invite`,
    });

    return response.data;
  }

  public async updateInvoicePayment(id: string): Promise<boolean> {
    const response = await this.request({
      url: `company/${id}/invoice-payment`,
      method: "PATCH",
    });

    return response.data.invoicePayment;
  }

  public async updateRequireDepartment(id: string): Promise<boolean> {
    const response = await this.request({
      url: `company/${id}/require-department`,
      method: "PATCH",
    });

    return response.data.requireDepartment;
  }

  public async updateBonusBalance(
    id: number,
    bonusBalance: number
  ): Promise<number> {
    const response = await this.request({
      url: `company/${id}/bonus-balance`,
      method: "PATCH",
      data: {
        bonusBalance,
      },
    });

    return response.data.bonusBalance;
  }

  public async updateAdditionalPrice(
    id: number,
    additionalPrice: number
  ): Promise<number> {
    const response = await this.request({
      url: `company/${id}/additional-price`,
      method: "PATCH",
      data: {
        additionalPrice,
      },
    });

    return response.data;
  }

  public async getCompanyUsers(id: number) {
    const response = await this.request({
      url: "/auth/users",
      method: "POST",
      data: {
        companyId: id,
        isBlocked: false,
        roles: ["company", "companyAdmin", "employee"],
      },
    });

    return response.data;
  }

  public async changeCustomFeedback(
    id: number,
    customFeedbackUrl: string,
    titleEn: string,
    titleDe: string,
    titleFr: string,
    titleIt: string
  ) {
    const response = await this.request({
      url: `company/${id}/custom-feedback`,
      method: "POST",
      data: {
        customFeedbackUrl,
        titleEn,
        titleDe,
        titleFr,
        titleIt,
      },
    });

    return response.data;
  }

  public async updateDepartment(
    id: number,
    selectedDepartmentId: number,
    titleEn: string,
    titleDe: string,
    titleFr: string,
    titleIt: string,
    costCenter?: string,
    parentDepartmentId?: number,
  ) {
    const response = await this.request({
      url: `company/${id}/department/${selectedDepartmentId}`,
      method: "PUT",
      data: {
        titleEn,
        titleDe,
        titleFr,
        titleIt,
        costCenter,
        parentDepartmentId
      },
    });

    return response.data;
  }

  public async addDepartment(
    id: number,
    titleEn: string,
    titleDe: string,
    titleFr: string,
    titleIt: string,
    costCenter?: string,
    parentDepartmentId?: number,
  ) {
    const response = await this.request({
      url: `company/${id}/department`,
      method: "POST",
      data: {
        titleEn,
        titleDe,
        titleFr,
        titleIt,
        costCenter,
        parentDepartmentId
      },
    });

    return response.data;
  }

  public async removeDepartment(
    companyId: number,
    departementId: number,
  ) {
    const response = await this.request({
      url: `company/${companyId}/department/${departementId}`,
      method: "DELETE",
    });

    return response.data;
  }

  public async blockInterpreter(
    companyId: number,
    interpreterId: number,
  ) {
    const response = await this.request({
      url: `company/block-interpreter/${companyId}/${interpreterId}`,
      method: "PUT",
    });

    return response.data;
  }

  public async unblockInterpreter(
    companyId: number,
    interpreterId: number,
  ) {
    const response = await this.request({
      url: `company/block-interpreter/${companyId}/${interpreterId}`,
      method: "DELETE",
    });

    return response.data;
  }

  public async getPastInterpreters(
    companyId: string,
  ) {
    const response = await this.request({
      url: `company/past-interpreter/${companyId}`,
      method: "GET",
    });

    return response.data;
  }

  public async switchCompanySessionRule(companyId: number, ruleId: number): Promise<void> {
    const response = await this.request({
      url: `/company/switch-rule/${companyId}/${ruleId}`,
      method: "PUT",
    });

    return response.data;
  }

  public async getCompanySessionRules(companyId: number): Promise<any> {
    const response = await this.request({
      url: `/company/session-rules/${companyId}`,
      method: "GET",
    });

    return response.data;
  }

  public async update2FAChange(id: string): Promise<boolean> {
    const response = await this.request({
      url: `company/${id}/2fa-change`,
      method: "PATCH",
    });

    return response.data.allowTwoFAChange;
  }
}

const instance = new CompanyService();

export default instance;
